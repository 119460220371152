<template>
	<v-sheet class="asset-stock" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex>
							<p class="my-auto font-level-3-bold">
								<span class="fw-500">
									<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
										<ShowValue :object="asset" object-key="name" label="asset stock name"></ShowValue>
									</b>
									<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{
										asset.barcode
									}}</v-chip>

									<template v-if="asset.retired == 1">
										<v-chip color="#F44336" text-color="white" label> RETIRED </v-chip>
									</template>
									<template v-else-if="asset.activated == 1">
										<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
									</template>
									<template v-else-if="asset.activated == 0">
										<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
									</template>
								</span>
							</p>
						</v-flex>
						<v-flex md6 class="text-right">
							<template v-if="asset.retired">
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
											><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
										>
									</template>
									<v-list>
										<v-list-item v-if="getPermission('asset-stock:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-btn
									v-if="false"
									v-on:click="doAction('print-label')"
									color="blue darken-4 text-white"
									class="mx-2"
									depressed
									tile
									><v-icon small left>mdi-printer</v-icon>Print Label</v-btn
								>
							</template>
							<template v-else>
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue darken-4 text-white"
											v-on="on"
											v-bind="attrs"
											class="mr-2"
											depressed
											tile
											><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
										>
									</template>

									<v-list>
										<v-list-item v-if="getPermission('asset-stock:update')" v-on:click="doAction('edit')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="getPermission('asset-stock:create')"
											v-on:click="doAction('duplicate')"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-content-duplicate</v-icon>
												Clone
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="getPermission('asset-stock:delete')" v-on:click="doAction('delete')">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-delete</v-icon>
												Delete
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<template>
									<v-btn
										v-if="false"
										v-on:click="doAction('extend-checkout')"
										color="blue darken-4 text-white"
										class="mx-2"
										depressed
										tile
										><v-icon small left>mdi-share-all-outline</v-icon>Extend Issue</v-btn
									>
									<template v-if="getPermission('checkin:create')">
										<v-btn
											v-if="asset.checkout_relation_count > 0"
											v-on:click="doAction('checkin')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-share-outline mdi-flip-h</v-icon>Receive</v-btn
										>
									</template>
								</template>
								<template v-if="!asset.retired && asset.stock_level > 0">
									<v-btn
										v-if="getPermission('asset-stock:update')"
										v-on:click="doAction('retire-stock')"
										color="blue darken-4 text-white"
										class="ml-2"
										depressed
										tile
										><v-icon small left>mdi-cancel</v-icon>Retire Stock</v-btn
									>
								</template>
								<template v-if="!asset.retired && asset.checkout_relation_count == 0">
									<v-btn
										v-if="getPermission('asset-stock:update')"
										v-on:click="doAction('retire')"
										color="blue darken-4 text-white"
										class="ml-2"
										depressed
										tile
										><v-icon small left>mdi-cancel</v-icon>Retire</v-btn
									>
								</template>
								<template>
									<template v-if="getPermission('checkout:create')">
										<v-btn
											v-if="asset.stock_level > 0"
											v-on:click="doAction('checkout')"
											color="blue darken-4 text-white"
											class="mx-2"
											depressed
											tile
											><v-icon small left>mdi-share-outline</v-icon>Issue</v-btn
										>
									</template>
									<template v-if="getPermission('reservation:create')">
										<v-btn
											v-on:click="doAction('reserve')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-calendar-check</v-icon>Reserve</v-btn
										>
									</template>
								</template>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="asset.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div v-if="asset.pico_barcode && asset.barcode_image" style="max-width: 300px">
								<div class="d-inline-block text-center">
									<img
										style="max-width: 100%; height: 30px"
										:src="asset.barcode_image"
										:alt="asset.pico_barcode"
									/>
									<pre class="mb-0 barcode-text bold-700">{{ asset.pico_barcode }}</pre>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Asset Stock # : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="barcode" label="asset #"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33" v-if="false">
									<div class="mt-2">
										<span class="fw-600">Incharge Officer : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="asset"
												object-key="member_relation.display_name"
												label="incharge officer"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600"> Purchased On : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="asset"
												object-key="purchased_on_formatted"
												label="Purchased On"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600"> Category : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="asset" object-key="group_relation.name" label="Category">
											</ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Supplier : </span>
										<span class="fw-500">
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="asset"
													object-key="supplier_relation.display_name"
													label="supplier"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Stock Level : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="asset"
												object-key="stock_level_formatted"
												label="Stock Level"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600"> Reorder Quantity : </span>
										<span class="fw-500">
											<ShowValue
												:object="asset"
												object-key="reorder_quantity_formatted"
												label="reorder quantity"
											></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Barcode : </span>
										<span class="fw-500">
											<ShowValue :object="asset" object-key="pico_barcode" label="barcode"> </ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="false" class="w-20 pr-3">
							<template v-if="asset.pico_barcode && asset.barcode_image">
								<span class="fw-600">Pico Barcode : </span>
								<span class="fw-500"><Barcode circle :src="asset.barcode_image"></Barcode></span>
							</template>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="assetTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab v-if="false" href="#quantity-by-location">
						<v-icon small left>mdi-map-marker-outline</v-icon> Quantity by Location</v-tab
					>
					<v-tab v-if="getPermission('checkout:view')" href="#checked-out">
						<v-icon small left>mdi-share-outline</v-icon> Issued</v-tab
					>
					<v-tab v-if="getPermission('reservation:view')" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab href="#retire_stock_records">
						<v-icon small left>mdi-calendar-check</v-icon> Retire Stock Records</v-tab
					>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab v-if="false" href="#work-order">
						<v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab
					>
					<v-tab v-if="getPermission('asset-stock:update')" href="#file">
						<v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab
					>
					<v-tab v-if="getPermission('asset-stock:update')" href="#comment">
						<v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab
					>
					<v-tab v-if="false" href="#support">
						<v-icon small left>mdi-page-next-outline</v-icon> Support</v-tab
					>
				</v-tabs>

				<v-tabs-items v-model="assetTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-stock-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Asset Stock Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th width="250px">Color</th>
										<td>
											<ShowValue :object="asset" object-key="color" label="color"></ShowValue>
										</td>
										<th width="250px">Size</th>
										<td>
											<ShowValue :object="asset" object-key="size" label="size"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Add Stock Price</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="price_add_stock_formatted"
												label="add stock price"
											></ShowValue>
										</td>
										<th width="250px">Contact for Item</th>
										<td>
											<ShowValue :object="asset" object-key="contact" label="contact for item"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Created On</th>
										<td colspan="3">
											<ShowValue
												:object="asset"
												object-key="created_at"
												tooltip
												tooltip-value="added_at_formatted"
												label="created on"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Description</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="description" label="description"> </ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Remark</th>
										<td colspan="3">
											<ShowValue :object="asset" object-key="remark" label="remark"> </ShowValue>
										</td>
									</tr>
									<!-- <tr>
										<th width="15%">Purchased On</th>
										<td width="35%">
											<ShowValue
												:object="asset"
												object-key="purchased_on_formatted"
												label="Purchased On"
											></ShowValue>
										</td>
										<th width="15%">Incharge Officer</th>
										<td width="35%">
											<ShowValue
												:object="asset"
												object-key="member_relation.display_name"
												label="incharge officer"
											></ShowValue>
										</td>
									</tr> -->
									<!-- <tr>
										<th>Category</th>
										<td>
											<ShowValue :object="asset" object-key="group_relation.name" label="Category"></ShowValue>
										</td>
										<th>Supplier</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="supplier_relation.display_name"
												label="supplier"
											></ShowValue>
										</td>
									</tr> -->
									<tr v-if="false">
										<th>Identification Number</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="id_number"
												label="identification number"
											></ShowValue>
										</td>
										<th>Vendor</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="supplier_relation.display_name"
												label="vendor"
											></ShowValue>
										</td>
									</tr>
									<!-- <tr>
										<th>Stock Level</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="stock_level_formatted"
												label="Stock Level"
											></ShowValue>
										</td>
										<th>Reorder Quantity</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="reorder_quantity_formatted"
												label="reorder quantity"
											></ShowValue>
										</td>
									</tr> -->
									<tr v-if="false">
										<th>Sub Group</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="sub_group_relation.name"
												label="sub group"
											></ShowValue>
										</td>
										<th>Total Quantity</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="total_quantity"
												label="total quantity"
											></ShowValue>
										</td>
									</tr>
									<!-- <tr>
										<th>
											Add Stock Price
											<TooltipQuestion>
												<template v-slot:text>Default price for adding new stock</template>
											</TooltipQuestion>
										</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="price_add_stock_formatted"
												label="add stock price"
											></ShowValue>
										</td>
										<th>Contact for Item</th>
										<td>
											<ShowValue :object="asset" object-key="contact" label="contact for item"></ShowValue>
										</td>
									</tr> -->

									<!-- <tr>
										<th>Purchased On</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="purchased_on_formatted"
												label="Purchased On"
											></ShowValue>
										</td>
										<th>Incharge Officer</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="member_relation.display_name"
												label="incharge officer"
											></ShowValue>
										</td>
									</tr>-->

									<tr v-if="false">
										<th>Available Quantity</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="available_quantity"
												label="available quantity"
											></ShowValue>
										</td>
										<th>Checked Out Quantity</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="checked_out_quantity"
												label="checked out quantity"
											></ShowValue>
										</td>
									</tr>
									<tr v-if="false">
										<th>
											Low Stock Threshold
											<TooltipQuestion>
												<template v-slot:text
													>You will receive an email alert<br />when the total stock quantity<br />becomes less
													than this value</template
												>
											</TooltipQuestion>
										</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="low_stock_formatted"
												label="low stock threshold"
											></ShowValue>
										</td>
									</tr>
									<tr v-if="false">
										<th>
											Default Low Location Threshold
											<TooltipQuestion>
												<template v-slot:text
													>For all locations without specified<br />thresholds, you will receive an<br />email
													alert when stock<br />quantity falls below this value</template
												>
											</TooltipQuestion>
										</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="default_threshold_relation.low_location_formatted"
												label="default low location threshold"
											></ShowValue>
										</td>
										<th>
											Default Excess Location Threshold
											<TooltipQuestion>
												<template v-slot:text
													>For all locations without specified<br />thresholds, you will receive an<br />email
													alert when stock<br />quantity is higher than this value</template
												>
											</TooltipQuestion>
										</th>
										<td>
											<ShowValue
												:object="asset"
												object-key="default_threshold_relation.excess_location_formatted"
												label="default excess location threshold"
											></ShowValue>
										</td>
									</tr>
									<tr v-if="false">
										<th>Default Location</th>
										<td colspan="3">
											<ShowValue
												:object="asset"
												object-key="location_relation.name"
												label="default location"
											></ShowValue>
										</td>
									</tr>
									<!-- <tr>
										<th>Description</th>
										<td colspan="2">
											<ShowValue :object="asset" object-key="description" label="description"></ShowValue>
										</td>
										<td></td>
									</tr> -->
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item v-if="false" value="quantity-by-location">
						<QuantityLocation
							v-if="assetTab == 'quantity-by-location'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
						></QuantityLocation>
					</v-tab-item>
					<v-tab-item value="checked-out">
						<ReservationListing
							v-if="assetTab == 'checked-out'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation">
						<ReservationListing
							v-if="assetTab == 'reservation'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="retire_stock_records">
						<ItemRetirementsListing
							v-if="assetTab == 'retire_stock_records'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:uuid="uuid"
						></ItemRetirementsListing>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="assetTab == 'event'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item v-if="false" value="work-order">
						<WorkOrderListing
							v-if="assetTab == 'work-order'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="assetTab == 'file'"
							class="mx-4"
							type-text="Asset Stock Files"
							type="asset-stock"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="assetTab == 'comment'"
							class="mx-4"
							type-text="Asset Stock"
							type="asset-stock"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="assetTab == 'support'"
							class="mx-4"
							type-text="Asset"
							type="asset"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Asset Stock"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!asset.retired
						? 'To preserve associated records, you could retire the Asset Stock instead.'
						: null
				"
			>
				<template v-if="!asset.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<RetireTemplate
				type="Asset Stock"
				endpoint="asset-stock"
				:type-uuid="uuid"
				v-on:success="goBack()"
				v-on:close="retireDialog = false"
				:retire-dialog="retireDialog"
			></RetireTemplate>
			<RetireStockTemplate
				type="Asset Stock"
				endpoint="asset-stock"
				:type-uuid="uuid"
				v-on:success="goBack()"
				v-on:close="retireStockDialog = false"
				:retire-dialog="retireStockDialog"
			></RetireStockTemplate>
			<ExtendCheckoutTemplate
				type="Asset Stock"
				endpoint="asset-stock"
				:type-uuid="uuid"
				:checkout-uuid="asset.checkout_uuid"
				v-on:success="
					extendCheckoutDialog = false;
					getAssetStock();
				"
				v-on:close="extendCheckoutDialog = false"
				:extend-checkout-dialog="extendCheckoutDialog"
			></ExtendCheckoutTemplate>
			<CheckoutTemplate
				type="Asset Stock"
				endpoint="asset-stock"
				:type-uuid="uuid"
				v-on:success="
					checkoutDialog = false;
					getAssetStock();
				"
				v-on:close="checkoutDialog = false"
				:checkout-dialog="checkoutDialog"
			></CheckoutTemplate>
			<template v-if="checkinDialog && checkout_uuid && uuid">
				<CheckinTemplate
					type="Asset Stock"
					endpoint="asset-stock"
					:type-uuid="uuid"
					:checkout-uuid="checkout_uuid"
					v-on:success="
						checkinDialog = false;
						getAssetStock();
					"
					v-on:close="checkinDialog = false"
					:checkin-dialog="checkinDialog"
				></CheckinTemplate>
			</template>
			<ReservationTemplate
				type="Asset Stock"
				endpoint="asset-stock"
				:type-uuid="uuid"
				v-on:success="
					reservationDialog = false;
					getAssetStock();
				"
				v-on:close="reservationDialog = false"
				:reservation-dialog="reservationDialog"
			></ReservationTemplate>
			<AddStockTemplate></AddStockTemplate>
			<template v-if="uuid && selectCheckoutDialog">
				<SelectCheckout
					:type-uuid="uuid"
					v-on:selectCheckout="
						(param) => {
							selectCheckoutDialog = false;
							selectCheckout(param);
						}
					"
					v-on:close="selectCheckoutDialog = false"
					:asset-stock-uuid="uuid"
					:checkout-dialog="selectCheckoutDialog"
				></SelectCheckout>
			</template>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ImageTemplate from "@/view/components/Image";
import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ShowValue from "@/view/components/ShowValue";
import QuantityLocation from "@/view/components/QuantityLocation";
import { GetAssetStock } from "@/core/lib/asset-stock.lib";
import ReservationListing from "@/view/components/ReservationListing";
import ItemRetirementsListing from "@/view/components/ItemRetirementsListing";
import EventListing from "@/view/components/EventListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RetireTemplate from "@/view/components/RetireTemplate";
import RetireStockTemplate from "@/view/components/RetireStockTemplate";
import SelectCheckout from "@/view/components/CheckoutListTemplate";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import ExtendCheckoutTemplate from "@/view/components/ExtendCheckoutTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import AddStockTemplate from "@/view/components/AddStockTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "asset-stock-detail",
	title: "Detail Asset Stock",
	data() {
		return {
			assetTab: null,
			uuid: null,
			barcode: null,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			retireDialog: false,
			selectCheckoutDialog: false,
			checkout_uuid: null,
			contentLoaded: false,
			retireStockDialog: false,
			checkoutDialog: false,
			reservationDialog: false,
			extendCheckoutDialog: false,
			checkinDialog: false,
			asset: {},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "asset-stock-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "asset-stock-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "retire":
					this.retireDialog = true;
					break;
				case "retire-stock":
					this.retireStockDialog = true;
					break;
				case "add-stock":
					EventBus.$emit("add:stock", { type: "asset-stock", typeUUID: this.uuid });
					break;
				case "stock-history":
					break;
				case "print-label":
					break;
				case "checkout-history":
					break;
				case "verification-history":
					break;
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "reserve":
					this.reservationDialog = true;
					break;
				case "extend-checkout":
					this.extendCheckoutDialog = true;
					break;
				case "checkin":
					this.selectCheckoutDialog = true;
					//this.checkinDialog = true;
					break;
			}
		},
		selectCheckout(param) {
			this.checkout_uuid = param.uuid;
			this.checkinDialog = true;
		},
		getAssetStock() {
			GetAssetStock(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `asset-stock/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		Comments,
		ShowValue,
		EventListing,
		TooltipQuestion,
		QuantityLocation,
		SelectCheckout,
		WorkOrderListing,
		ReservationListing,
		ItemRetirementsListing,
		ImageTemplate,
		DeleteTemplate,
		RetireTemplate,
		RetireStockTemplate,
		CheckinTemplate,
		CheckoutTemplate,
		ExtendCheckoutTemplate,
		ReservationTemplate,
		AddStockTemplate,
		SupportTemplate,
		Barcode,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset Stock", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getAssetStock();

		EventBus.$on("reload:asset-stock", () => {
			this.getAssetStock();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-stock");
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
